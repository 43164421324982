<template>
<div>
    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadísticas</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">RESULTADOS DE LAS AUDITORÍAS INTERNAS</span>
                    <hr>
                    <CChartPie :options="optionsEstadistica" class="my-2" :datasets="dataSetsEstadistica" :labels="labelEstadistica" />
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog fa-md"></i> <span class="h5"> Gestión de Auditorías internas</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col  v-if="checkPermissions('010-MEJ-AUD','c')==1" cols="6" class="my-2">
                            <b-button block :to="{name: 'Gestión Auditoría interna'}" size="md" variant="custom">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br> Nueva auditoría
                            </b-button>
                        </b-col>
                        <b-col  v-if="checkPermissions('010-MEJ-AUD-NOR','r')==1"  cols="6" class="my-2">
                            <b-button block :to="{name: 'Normas'}" size="md" variant="custom">
                                <i style="inline-size: auto" class="fas fa-certificate fa-3x pb-1"></i><br> Normas
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5"> Auditorías internas registradas</span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col lg="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col lg="7">
                        </b-col>
                        <b-col lg="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                    <!--     <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>-->
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table :items="listaAuditorias" :fields="campoAuditorias" bordered :per-page="porPagina" :current-page="currentPage" @filtered="onFiltered" :filter="filter" :filter-included-fields="filterOn" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template #cell(opciones)="param">
                                    <b-button  v-if="checkPermissions('010-MEJ-AUD','u')==1"  :to="{name: 'Gestión Auditoría interna',params: {id: param.item.idAuditoriaInterna}}" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-button>
                                    <b-button :disabled="disabled" @click="generarInforme(param)" size="sm" class="mr-1 mb-1" variant="blue" v-c-tooltip.hover.click="'Descargar informe'">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-download my-0 mx-0"></i>
                                        </span>
                                    </b-button>

                                    <b-button  v-if="checkPermissions('010-MEJ-AUD','d')==1"  @click="eliminarAuditorias(param)" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                    </b-button>
                                </template>
                                <template v-slot:cell(isFinalizado)="data">
                                    <b-icon variant="success" v-if="data.value==2" icon="check-circle-fill"></b-icon>
                                    <b-icon variant="danger" v-else icon="x-circle-fill"></b-icon>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="porPagina" align="right" size="sm" class="my-0"></b-pagination>
                </CCardFooter>
            </CCard>
        </b-col>
    </b-row>

</div>
</template>

<script>
import {
    CChartPie
} from '@coreui/vue-chartjs'

import axios from "axios";
import CONSTANTES from "@/Constantes.js";

import Docxtemplater from "docxtemplater";
import {
    saveAs
} from "file-saver";
import ImageModule from "docxtemplater-image-module-free";
import JSZipUtils from "jszip-utils";
import JSZip from "jszip";

import expressions from 'angular-expressions';
import assign from "lodash.assign";

expressions.filters.lower = function (input) {
    // This condition should be used to make sure that if your input is
    // undefined, your output will be undefined as well and will not
    // throw an error
    if (!input) return input;
    return input.toLowerCase();
}

function angularParser(tag) {
    if (tag === '.') {
        return {
            get: function (s) {
                return s;
            }
        };
    }
    const expr = expressions.compile(
        tag.replace(/(’|‘)/g, "'").replace(/(“|”)/g, '"')
    );
    return {
        get: function (scope, context) {
            let obj = {};
            const scopeList = context.scopeList;
            const num = context.num;
            for (let i = 0, len = num + 1; i < len; i++) {
                obj = assign(obj, scopeList[i]);
            }
            return expr(scope, obj);
        }
    };
}

export default {
    components: {
        CChartPie
    },
    data() {
        return {
            showChart: false,
            datosSession: {
                idCliente: null
            },
            labelEstadistica: ["OK", "NA", "DESVIACIÓN", "ODM"],
            optionsEstadistica: {
                legend: {
                    labels: {
                        fontSize: 15,
                    },
                    position: 'left',
                    align: 'center'
                },
            },
            totalRows: 1,
            currentPage: 1,
            filter: null,
            filterOn: [],
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],
            campoAuditorias: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                }, {
                    key: "empresa",
                    label: "Empresa",
                    class: "text-center",
                }, {
                    key: "fecha",
                    label: "Fecha",
                    class: "text-center",
                }, {
                    key: "alcance",
                    label: "Alcance",
                    class: "text-center",
                }, {
                    key: "auditorJefe",
                    label: "Auditor jefe",
                    class: "text-center",
                }, {
                    key: "norma",
                    label: "Norma",
                    class: "text-center",
                }, {
                    key: "isFinalizado",
                    label: "Finalizada",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center"
                }
            ],
            listaDetalleAuditorias: [],
            listaAuditorias: [],
            disabled: false
        }
    },
    methods: {
        
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        base64DataURLToArrayBuffer(dataURL) {
            const base64Regex = /^data:image\/(png|jpg|svg|svg\+xml);base64,/;
            if (!base64Regex.test(dataURL)) {
                return false;
            }
            const stringBase64 = dataURL.replace(base64Regex, "");
            let binaryString;
            if (typeof window !== "undefined") {
                binaryString = window.atob(stringBase64);
            } else {
                binaryString = new Buffer(stringBase64, "base64").toString("binary");
            }
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                const ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }
            return bytes.buffer;
        },
        generarInforme(param){
            let me = this;
            //me.swat('info', 'Generando reporte espere porfavor ...')
            me.disabled = true;

            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/generar-informe-auditoria-interna", {
                        params: {
                            idCliente: param.item.idCliente,
                            idAuditoriaInterna: param.item.idAuditoriaInterna,
                            idNorma: param.item.idNorma,
                            logo: JSON.parse(localStorage.settings).logo,
                        }
                    }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
           
                    JSZipUtils.getBinaryContent(`${CONSTANTES.URL_RUTA_FRONTEND}plantillas/plantilla-informe-auditoria-interna.docx`, function (error, content) {
                        if (error) {
                            console.error(error);
                            return;
                        }
                        var opts = {}
                        opts.centered = true;
                        const imageOpts = {
                            getImage(tag) {
                                return me.base64DataURLToArrayBuffer(tag);
                            },
                            getSize() {
                                return [430, 250];
                            },
                        };

                        var imageModule = new ImageModule(imageOpts);

                        var zip = new JSZip(content);
                        var doc = new Docxtemplater()
                            .loadZip(zip)
                            .setOptions({
                                parser: angularParser
                            })
                            .attachModule(imageModule);
                        doc.setData({
                            //image: response.data.datosAsistentes[0].urlFirma,
                            logo: response.data.logo,
                            fecha: param.item.fecha,
                            empresa: param.item.empresa,
                            lugar: param.item.lugar,
                            alcance: param.item.alcance,
                            auditorJefe: param.item.auditorJefe,
                            auditor: param.item.auditor,
                            norma: param.item.norma,
                            listaPestanas: response.data.dataPestanas,
                            listaPuntos: response.data.dataPuntos,
                            listaPreguntas: response.data.dataPreguntas,
                        });

                        try {
                            doc.render();
                        } catch (error) {
                            let e = {
                                message: error.message,
                                name: error.name,
                                stack: error.stack,
                                properties: error.properties
                            };
                            console.log(e)
                            throw error;
                        }
                        var out = doc.getZip().generate({
                            type: "blob",
                            mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        });
                        saveAs(out, "informe-auditoria-interna.docx");
                        //me.disabled = false;
                    });
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        listarAuditoriasInternas() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-auditorias-internas", {
                        params: {
                            idCliente: me.datosSession.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.listaAuditorias = response.data;
                    me.totalRows = me.listaAuditorias.length;
                    me.contarEstadisticas();
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        contarEstadisticas() {

            let me = this;
            me.showChart = false;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/contar-detalles-auditoria-interna", {
                        params: {
                            idCliente: me.datosSession.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.listaDetalleAuditorias = response.data;
                    me.showChart = true;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        eliminarAuditorias(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar la auditoría?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    let me = this;
                    axios
                        .post(
                            CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-auditoria-interna", {
                                idAuditoriaInterna: param.item.idAuditoriaInterna
                            }, {
                                headers: {
                                    Authorization: `Bearer ${localStorage.token}`
                                },
                            }
                        )
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarAuditoriasInternas();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {
        dataSetsEstadistica() {
            return [{
                borderWidth: 3,
                backgroundColor: [
                    '#f16553d4',
                    '#6bba70db',
                    '#428bca94',
                    '#fdcd60cc',
                ],
                data: [this.listaDetalleAuditorias.filter(x => x.resultado == 1).length, this.listaDetalleAuditorias.filter(x => x.resultado == 2).length, this.listaDetalleAuditorias.filter(x => x.resultado == 3).length, this.listaDetalleAuditorias.filter(x => x.resultado == 4).length]
            }]
        }
    },
    watch: {

    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosSession.idCliente = user.uidClient;
            this.listarAuditoriasInternas();

        }
    }

}
</script>
